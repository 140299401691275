.btn-secondary {
  @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}
.btn-accent {
  @include button-variant($btn-accent-color, $btn-accent-bg, $btn-accent-border);
}
.btn-pdf {
  @include button-variant($btn-pdf-color, $btn-default-bg, $btn-default-border);
}
.btn-map {
  @include button-variant($btn-map-color, $btn-default-bg, $btn-default-border);
}
.btn-netvisor {
  @include button-variant(#FFFFFF, $btn-netvisor-color, darken($btn-netvisor-color, 5%));
}
.btn-procountor {
  @include button-variant(#FFFFFF, $btn-procountor-color, darken($btn-procountor-color, 5%));
}
.btn-netbaron {
  @include button-variant(#FFFFFF, $btn-netbaron-color, darken($btn-netbaron-color, 5%));
}

.btn-delete {
  @extend .btn-link;
  color: $brand-danger;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .2s;
}