.progress-bar {
    color: #333;
    line-height: 30px;
    font-size:13px;
}

.progress-bar-default {
    @include progress-bar-variant($progress-bar-default-bg);
}

.progress-bar-disabled {
    @include progress-bar-variant($progress-bar-disabled-bg);
}

.progress-bar-success-outline {
    color: $state-success-text;
    border: 2px solid $state-success-text;
    background-color: #FFFFFF;
    line-height: 28px;
}

.progress-bar-danger-outline {
    color: $state-danger-text;
    border: 2px solid $state-danger-text;
    background-color: #FFFFFF;
    line-height: 28px;
}