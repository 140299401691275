.wizard {
  margin: 0px auto;
  background: #fff;
}

.wizard .nav-tabs {
  position: relative;
  margin: 0px -10px;
  margin-bottom: 0;
  border-bottom-color: $brand-secondary;
  border-bottom-width: 2px;
}

.wizard > div.wizard-inner {
  position: relative;
  margin-bottom:20px;
}

.connecting-line {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 80%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  z-index: 1;
}

.wizard .nav-tabs > li.active > a, .wizard .nav-tabs > li.active > a:hover, .wizard .nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}

span.round-tab {
  width: 60px;
  height: 60px;
  line-height: 57px;
  display: inline-block;
  border-radius: 100px;
  background: #fff;
  border: 2px solid #e0e0e0;
  z-index: 2;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 22px;
}
span.round-tab i{
  color:#555555;
}
.wizard li.active span.round-tab {
  background: #fff;
  border: 2px solid $brand-secondary;

}
.wizard li.active span.round-tab i{
  color:  $brand-secondary;
}

span.round-tab:hover {
  color: #333;
  border: 2px solid #333;
}

.wizard .nav-tabs > li {
  width: 20%;
}

.wizard li:after {
  content: " ";
  position: absolute;
  left: 45%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: $brand-secondary;
  transition: 0.1s ease-in-out;
}

.wizard li.active:after {
  content: " ";
  position: absolute;
  left: 45%;
  opacity: 1;
  margin: 0 auto;
  bottom: 0px;
  border: 10px solid transparent;
  border-bottom-color: $brand-secondary;
}

.wizard .nav-tabs > li a {
  width: 60px;
  height: 60px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
}

.wizard .nav-tabs > li a:hover {
  background: transparent;
}

.wizard .tab-pane {
  position: relative;
  padding-top: 50px;
}

.wizard h3 {
  margin-top: 0;
}

@media( max-width : 585px ) {

  .wizard {
    width: 90%;
    height: auto !important;
  }

  span.round-tab {
    font-size: 16px;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .wizard .nav-tabs > li a {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .wizard li.active:after {
    content: " ";
    position: absolute;
    left: 35%;
  }
}