@import "variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "buttons";
@import "progress-bars";
@import "panels";
@import "form-tabs";
@import "animate";
@import "document";
//@import "search-select";
//@import "node_modules/sweetalert/themes/twitter/twitter.scss";
@import "sweetalert";
// @import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700,600,400italic);
//@import "node_modules/bootstrap-select/sass/bootstrap-select";

/*
 * Base structure
 */

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  padding-top: 70px;
  background-color: #FCFCFC;
}

textarea {
  resize: vertical;
}

@include text-emphasis-variant('.text-secondary', $brand-secondary);

label {
  text-transform: uppercase;
  color: #2d3748;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.025em;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  margin-bottom: 8px;
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
  margin-right: 3px;
  position: initial;
}

/* Change navbar breakpoint*/
@media (max-width: 1024px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }
}

.strikethrough {
  text-decoration: line-through;
}

.table {
  margin-bottom: 15px;
  > thead > tr > th {
    background-color:  #f5f5f5;
    color: #032B58;
    text-transform: uppercase;
    font-size: 11px;
    padding: 10px 8px;
    letter-spacing: 0.025em;
  }
  > tbody > tr > th {
    background-color: #FDFDFD;
  }
  > tbody > tr > td {
    vertical-align: middle;
  }
}

.table-form {
  border: 1px solid #ddd;
  > tbody > tr {
    border: none;
  }
  > tbody > tr > th {
    text-transform: uppercase;
    white-space: nowrap;
    color: #2d3748;
    width: 10rem;
    font-weight: 600;
    font-size: 11px;
    vertical-align: top;
    padding-top: 10px;
    padding-right: 10px;
    background-color: #f7fafc;
    letter-spacing: 0.025em;
  }
  > tbody > tr > td {
    padding-left: 10px;
  }
}

@media (max-width: 767px) {
    .table-form {
        border: none;
    }
}

.input-group-addon {
    font-size: 12px;
    padding: 8px;
    background-color: #f2f2f2;
}

.unit-input {
  position: relative;
  display: table;
  border-collapse: separate;
  display: flex;
  align-items: stretch;
  > input {
    padding-right: 2.125rem !important;
  }
  .unit {
    display: flex;
    margin-left: -25px;
    align-items: center;
    justify-content: center;
    flex: 1;
    > span {
      /*tw-flex tw-items-center tw-leading-normal tw-rounded tw-rounded-l-none tw-border tw-border-l-0 tw-border-grey-light tw-px-3 tw-whitespace-no-wrap tw-text-gray-600 tw-text-lg*/
      font-size: 12px;
      color: #718096;
      border-width: 1px !important;
      border-radius: .25rem !important;
      align-items: center;
      display: flex;
      white-space: nowrap;
      box-sizing: border-box;
    }
  }
}




/* Disable horizontal scrollbar on wider screens */
@media (min-width: 767px) {
  .table-responsive {
    overflow: inherit !important;
  }
}

.table-invoice {
  > thead,
  > tbody {
    > tr > th {
      background-color: #edf2f7;
      color: #4a5568;
      font-size: 80%;
      font-weight: 600;
      border-bottom-width: 1px;
    }
  }

  > thead,
  > tbody {
    > tr > th.table-title {
      background-color: #edf2f7;
      font-size: 100%;
      color: $brand-primary;
      font-weight: bold;
    }
  }

}

/* Full screen modal */
@mixin modal-fullscreen() {
  padding: 0 !important;

  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border: 0 none;
    border-radius: 0;
  }

}

@media (max-width: 767px) {
  .modal-fullscreen-xs-down {
    @include modal-fullscreen();
  }
}

@media (max-width: 991px) {
  .modal-fullscreen-sm-down {
    @include modal-fullscreen();
  }
}

@media (max-width: 1199px) {
  .modal-fullscreen-md-down {
    @include modal-fullscreen();
  }
}

.modal-fullscreen {
  @include modal-fullscreen();
}

.state-column {
  width:120px;
  text-align: center;
}

.number-column {
  text-align: right;
  white-space: nowrap;
}

.panel-default {
  > .panel-heading {
    border-bottom: 2px solid $brand-secondary;
    > .panel-title {
      color: $brand-primary;
    }
  }
}

.panel-login {
  > .panel-footer {
      background-color: $brand-primary;
    }
}

.table-row-selection > tbody > tr {
    cursor: pointer;
}

.label-success-outline {
  background-color: #FFFFFF;
  color: $state-success-text;
  border: 2px solid $state-success-text;
}

.label-danger-outline {
  background-color: #FFFFFF;
  color: $state-danger-text;
  border: 2px solid $state-danger-text;
}

.label-weighing-in {
  font-size: 80%;
  font-weight: 600;
  min-width: 58px;
  background-color: $state-success-bg;
  border: 1px solid $state-success-text;
}

.label-light-success {
  font-size: 80%;
  font-weight: 600;
  min-width: 60px;
  background-color: $state-success-bg;
  border: 1px solid $state-success-text;
  padding: 4px 8px;
}

.label-light-danger {
  font-size: 80%;
  font-weight: 600;
  min-width: 60px;
  background-color: $state-danger-bg;
  border: 1px solid $state-danger-text;
  padding: 4px 8px;
}

.label-weighing-out {
  font-size: 80%;
  font-weight: 600;
  min-width: 58px;
  background-color: $state-danger-bg;
  border: 1px solid $state-danger-text;
}

.label-weighing-info {
  font-size: 80%;
  font-weight: 600;
  min-width: 58px;
  background-color: $state-info-bg;
  border: 1px solid $state-info-text;
}

.label-accounting {
  background-color: #FFFFFF;
  color: $state-info-text;
  border: 2px solid $state-info-text;
}

.label-outline-success {
  background-color: #FFFFFF;
  color: $state-success-text;
  padding: .4em .8em .4em;
  border: 3px solid $state-success-text;
}

td > span.label {
  display:block;
  line-height: normal;
}

.panel-body {
  padding: 7px 10px 7px 10px;
}

.flex-panel-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel-buttons {
  float:right;
  margin-top: -4px;
}

.invoice-title {
  font-size: 80%;
  color: #777777;
}

.thick-border {
  border-top: 2px solid #ccc;
}

.invoice-status-container {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1000px;
  left: 0;
  right: 0;
  display: inline-block;
  text-align: center;
  -ms-transform: rotate(-12deg);
  -webkit-transform: rotate(-12deg);
  transform: rotate(-12deg);
  > span {
    display: inline-block;
    padding: 12px 18px;
    border-radius: 4px;
  }
}

.well-info {
  background-color: $state-info-bg;
}
.well-success {
  background-color: $state-success-bg;
}

.centered {
  position: fixed; /* or absolute */
  top: 45%;
  left: 50%;
  z-index: 100;
}

fieldset {
  margin-top: 15px;
}

legend {
  color: #666666;
  font-size: 85%;
}

.scale-display {
  width: 100%;
  display: inherit;
  padding: 8px;
  font-family: monospace;
  font-size: 90%;
  font-weight: 300;
  border-radius: 3px;
  /*background-color: $brand-primary;
  color: #FFFFFF;*/
}

// Vue Transition
.expand-transition {
  transition: all .3s ease;
  height: 30px;
  padding: 10px;
  overflow: hidden;
}

.expand-enter, .expand-leave {
  height: 0;
  padding: 0 10px;
  opacity: 0;
}

.staggered-transition {
  transition: all .5s ease;
  overflow: hidden;
  margin: 0;
  height: 20px;
}
.staggered-enter, .staggered-leave {
  opacity: 0;
  height: 0;
}

.divide {
  border-right: 1px solid #ccc;
  padding-right: 10px;
  margin-right: -5px;
}

.required:after {
  content:"*";
  margin-left:2px;
  color: $brand-danger;
}

.text-white {
  color: #FFFFFF;
}

.form-label {
  margin-top: 10px;
}

body.modal-open {
  overflow: hidden;
  position:fixed;
  width: 100%;
}

.mobile-title {
    font-size: 65%;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #2d3748;
    display: none;
    font-weight: 600;
    margin-bottom: 6px;
    background-color: #f7fafc;
    padding: 6px 6px;
    margin-top: -8px;
    margin-left: -11px;
    margin-right: -11px;
}

@media (max-width: 767px) {
    .mobile-title {
        display: block !important;
    }
}

/** Tailwind utils */
.tw-tracking-wide {
  letter-spacing: 0.05em;
}

.tw-truncate	{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.input-suffix {
    pointer-events: none !important;
    color: #4a5568 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    position: absolute !important;
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    display: flex !important;
    align-items: center !important
}

.tw-whitespace-no-wrap { white-space: nowrap;	}
.tw-whitespace-pre-wrap { white-space: pre-wrap; }

.tw-uppercase	{ text-transform: uppercase; }
.tw-lowercase { text-transform: lowercase; }
.tw-no-underline { text-decoration: none; }

.tw-inline {
  display: inline !important;
}

/** Flex */
.tw-flex { display: flex; }
.tw-inline-flex { display: inline-flex; }

.tw-flex-row { flex-direction: row; }
.tw-flex-row-reverse { flex-direction: row-reverse; }
.tw-flex-col { flex-direction: column; }
.tw-flex-col-reverse { flex-direction: column-reverse; }

.tw-flex-no-wrap	{ flex-wrap: nowrap; }
.tw-flex-wrap	{ flex-wrap: wrap; }
.tw-flex-wrap-reverse	{ flex-wrap: wrap-reverse; }

.tw-items-stretch { align-items: stretch; }
.tw-items-start { align-items: flex-start; }
.tw-items-center { align-items: center; }
.tw-items-end { align-items: flex-end; }
.tw-items-baseline { align-items: baseline; }

.tw-content-start { align-content: flex-start; }
.tw-content-center	{ align-content: center; }
.tw-content-end { align-content: flex-end; }
.tw-content-between { align-content: space-between; }
.tw-content-around	{ align-content: space-around; }

.tw-self-auto { align-self: auto; }
.tw-self-start	{ align-self: flex-start; }
.tw-self-center { align-self: center; }
.tw-self-end { align-self: flex-end; }
.tw-self-stretch { align-self: stretch; }

.tw-justify-start { justify-content: flex-start; }
.tw-justify-center { justify-content: center; }
.tw-justify-end { justify-content: flex-end; }
.tw-justify-between { justify-content: space-between; }
.tw-justify-around { justify-content: space-around; }

.tw-flex-initial { flex: 0 1 auto; }
.tw-flex-1 { flex: 1 1 0%; }
.tw-flex-auto { flex: 1 1 auto; }
.tw-flex-none { flex: none; }
.tw-flex-grow { flex-grow: 1; }
.tw-flex-shrink { flex-shrink: 1; }
.tw-flex-no-grow { flex-grow: 0; }
.tw-flex-no-shrink { flex-shrink: 0; }

.tw-h-screen { height: 100vh; }

.tw-inline-block { display: inline-block !important; }

.tw-mt-0 { margin-top: 0; }
.tw-mr-0 { margin-right: 0; }
.tw-mb-0 { margin-bottom: 0; }
.tw-ml-0 { margin-left: 0; }
.tw-mt-1 { margin-top: 0.25rem; }
.tw-mr-1 { margin-right: 0.25rem; }
.tw-mb-1 { margin-bottom: 0.25rem; }
.tw-ml-1 { margin-left: 0.25rem; }
.tw-mt-2 { margin-top: 0.5rem; }
.tw-mr-2 { margin-right: 0.5rem; }
.tw-mb-2 { margin-bottom: 0.5rem; }
.tw-ml-2 { margin-left: 0.5rem; }
.tw-m-3 { margin: 0.75rem; }
.tw-mt-3 { margin-top: 0.75rem; }
.tw-mr-3 { margin-right: 0.75rem; }
.tw-mb-3 { margin-bottom: 0.75rem; }
.tw-ml-3 { margin-left: 0.75rem; }
.tw-mt-4 { margin-top: 1rem; }
.tw-mr-4 { margin-right: 1rem; }
.tw-mb-4 { margin-bottom: 1rem; }
.tw-ml-4 { margin-left: 1rem; }
.tw-m-5 { margin: 1.25rem; }
.tw-mt-5 { margin-top: 1.25rem; }
.tw-mr-5 { margin-right: 1.25rem; }
.tw-mb-5 { margin-bottom: 1.25rem; }
.tw-ml-5 { margin-left: 1.25rem; }
.tw-m-6 { margin: 1.5rem; }
.tw-mt-6 { margin-top: 1.5rem; }
.tw-mr-6 { margin-right: 1.5rem; }
.tw-mb-6 { margin-bottom: 1.5rem; }
.tw-ml-6 { margin-left: 1.5rem; }
.tw-mx-1 { margin-right: 0.25rem; margin-left: 0.25rem; }
.tw-mx-2 { margin-right: 0.5rem; margin-left: 0.5rem; }
.tw-mx-3 { margin-right: 0.75rem; margin-left: 0.75rem; }
.tw-mx-4 { margin-right: 1rem; margin-left: 1rem; }
.tw-mx-5 { margin-right: 1.25rem; margin-left: 1.25rem; }
.tw-my-2 { margin-top: 0.5rem; margin-bottom: 0.5rem; }
.tw-my-3 { margin-top: 0.75rem; margin-bottom: 0.75rem; }
.tw-my-4 { margin-top: 1rem; margin-bottom: 1rem; }
.tw-my-5 { margin-top: 1.25rem; margin-bottom: 1.25rem; }

.tw-p-0 { padding: 0 !important; }
.tw-p-1 { padding: 0.25rem; }
.tw-p-2 { padding: 0.5rem; }
.tw-p-3 { padding: 0.75rem; }
.tw-p-4 { padding: 1rem; }
.tw-p-5 { padding: 1.25rem; }
.tw-p-6 { padding: 1.5rem; }
.tw-p-7 { padding: 1.75rem; }
.tw-p-8 { padding: 2rem; }
.tw-p-10 { padding: 2.5rem; }
.tw-p-12 { padding: 3rem; }
.tw-p-16 { padding: 4rem; }
.tw-p-20 { padding: 5rem; }

.tw-pt-5 { padding-top: 1.25rem !important; }

.tw-pb-2 { padding-bottom: 0.5rem !important; }
.tw-pb-3 { padding-bottom: 0.75rem !important; }
.tw-pb-5 { padding-bottom: 1.25rem !important; }

.tw-pr-0 { padding-right: 0 !important; }
.tw-pr-1 { padding-right: 0.25rem !important; }
.tw-pr-2 { padding-right: 0.5rem !important; }
.tw-pr-3 { padding-right: 0.75rem !important; }
.tw-pr-4 { padding-right: 1rem !important; }
.tw-pr-5 { padding-right: 1.25rem !important; }
.tw-pr-6 { padding-right: 1.5rem !important; }
.tw-pr-7 { padding-right: 1.75rem !important; }
.tw-pr-8 { padding-right: 2rem !important; }
.tw-pr-9 { padding-right: 2.25rem !important; }
.tw-pr-10 { padding-right: 2.5rem !important; }

.tw-py-0 { padding-top: 0; padding-bottom: 0; }
.tw-py-1 { padding-top: 0.25rem; padding-bottom: 0.25rem; }
.tw-py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.tw-py-3 { padding-top: 0.75rem; padding-bottom: 0.75rem; }
.tw-py-4 { padding-top: 1rem; padding-bottom: 1rem; }

.tw-p-px { padding: 1px; }
.tw-px-1 { padding-left: 0.25rem; padding-right: 0.25rem; }
.tw-px-2 { padding-left: 0.5rem; padding-right: 0.5rem; }
.tw-px-5 { padding-left: 1.25rem; padding-right: 1.25rem; }

.tw-rounded	{ border-radius: .25rem !important; }
.tw-rounded-lg { border-radius: 0.5rem !important; }
.tw-rounded-full { border-radius: 9999px !important; }
.tw-border { border-width: 1px !important; }
.tw-border-solid { border-style: solid !important; }
.tw-border-0	{ border-width: 0; }

.tw-border-gray-400 { border-color: #cbd5e0; }

.tw-bg-gray-100 { background-color: #f7fafc; }
.tw-bg-gray-200 { 	background-color: #edf2f7; }

.tw-w-2 { width: 0.5rem; }
.tw-w-3 { width: 0.75rem; }
.tw-w-4 { width: 1rem; }
.tw-w-5 { width: 1.25rem; }
.tw-w-6 { width: 1.5rem; }
.tw-w-7 { width: 1.75rem; }
.tw-w-8 { width: 2rem; }
.tw-w-9 { width: 2.25rem; }
.tw-w-10 { width: 2.5rem; }
.tw-w-12 { width: 3rem; }
.tw-w-16 { width: 4rem; }
.tw-w-20 { width: 5rem; }
.tw-w-24 { width: 6rem; }
.tw-w-28 { width: 7rem; }
.tw-w-32 { width: 8rem !important; }
.tw-w-40 { width: 10rem !important; }
.tw-w-44 { width: 11rem !important; }
.tw-w-48 { width: 12rem !important; }
.tw-w-52 { width: 13rem; }
.tw-w-56 { width: 14rem; }
.tw-w-60 { width: 15rem; }
.tw-w-64 { width: 16rem; }
.tw-w-68 { width: 17rem; }
.tw-w-72 { width: 18rem; }
.tw-w-76 { width: 19rem; }
.tw-w-82 { width: 20rem; }

.tw-w-1-2 { width: 50%; }
.tw-w-1-3 { width: 33.333333%; }
.tw-w-2-3 { width: 66.666667%; }
.tw-w-1-4 { width: 25%; }

.tw-h-2 { height: 0.5rem; }
.tw-h-3 { height: 0.75rem; }
.tw-h-4 { height: 1rem; }
.tw-h-5 { height: 1.25rem; }
.tw-h-6 { height: 1.5rem; }
.tw-h-7 { height: 1.75rem; }
.tw-h-8 { height: 2rem; }
.tw-h-9 { height: 2.25rem; }
.tw-h-10 { height: 2.5rem; }

.tw-bg-white { background-color: #fff; }
.tw-bg-primary { background-color: $brand-primary; }
.tw-bg-gray-200 { background-color: #edf2f7; }
.tw-bg-gray-300 { background-color: #e2e8f0; }


.tw-text-white { color: #fff; }
.tw-text-primary { color: $brand-primary; }
.tw-text-secondary { color: $brand-secondary; }
.tw-text-gray-600	{ color: #718096; }
.tw-text-gray-700	{ color: #4a5568; }
.tw-text-gray-800	{ color: #2d3748; }
.tw-text-gray-900	{ color: #1a202c; }

.tw-text-xs { font-size: .75rem !important; }
.tw-text-sm { font-size: .875rem !important; }
.tw-text-base { font-size: 1rem !important; }
.tw-text-lg { font-size: 1.125rem !important; }
.tw-text-xl { font-size: 1.25rem !important; }
.tw-text-2xl { font-size: 1.5rem !important; }
.tw-text-3xl { font-size: 1.875rem !important; }

.tw-align-middle { vertical-align: middle !important; }

.tw-shadow	{
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.tw-shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.tw-cursor-pointer { cursor: pointer; }
