// Google Theme for SweetAlert
// By Tristan Edwards

.sweet-overlay {
  background: rgba(10,10,10,.6);
}


.sweet-alert {
  $header-height: 45px;
  $footer-height: 66px;
  $text-color: #333333;
  $padding: 15px;
  $error-color: #d9453c;

  font-family: Arial,"Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: $padding;
  padding-top: $header-height + $padding;
  padding-bottom: 13px;
  text-align: right; // Align buttons
  border-radius: 0;
  box-shadow: 0 0 14px rgba(black, 0.24),0 14px 28px rgba(black, 0.48);

  h2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    font-weight: 400;
    color: $text-color;
    margin: 20px 0;
    font-size: 20px;
    line-height: 1.25;
    text-align: left;
    padding: 0 $padding $padding $padding;
    border-bottom: 1px solid #e5e5e5;
  }

  p {
    display: block;
    text-align: center;
    color: $text-color;
    font-weight: 400;
    font-size: 16px;
    margin: 20px 0;
  }

  button {
    border-radius: 2px;
    font-size: 14px;
    padding: 6px 12px;
    position: relative;
    margin-top: 0;

    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    line-height: 1.428571429;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.confirm {
      color: $btn-secondary-color !important;
      background-color: $btn-secondary-bg !important;
      border-color: $btn-secondary-border !important;
      &:focus,
      &.focus {
        color: $btn-secondary-color !important;
        background-color: darken($btn-secondary-bg, 10%) !important;
        border-color: darken($btn-secondary-border, 25%) !important;
      }
      &:hover {
        color: $btn-secondary-color !important;
        background-color: darken($btn-secondary-bg, 10%) !important;
        border-color: darken($btn-secondary-border, 12%) !important;
      }

    }

    &.cancel {
      color: $btn-default-color !important;
      background-color: $btn-default-bg !important;
      border-color: $btn-default-border !important;
      &:focus,
      &.focus {
        color: $btn-default-color !important;
        background-color: darken($btn-default-bg, 10%) !important;
        border-color: darken($btn-default-border, 25%) !important;
      }
      &:hover {
        color: $btn-default-color !important;
        background-color: darken($btn-default-bg, 10%) !important;
        border-color: darken($btn-default-border, 12%) !important;
      }
    }
  }

  .sa-icon:not(.sa-custom) {
    transform: scale(0.8);
    //margin-bottom: -10px;
    //margin-top: -10px;
  }

  input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c9c9c9;
    color: #212121;
    margin-bottom: 8px;
    padding: 1px;
    padding-bottom: 8px;
    height: auto;
    box-shadow: none;
    font-size: 13px;
    margin: 10px 0;
    &:focus {
      border: none;
      border-bottom: 1px solid #3c80f6;
      box-shadow: inset 0 -1px 0 #3c80f6;
    }
  }

  fieldset {
    padding: 0;
    .sa-input-error {
      display: none;
    }
  }

  .sa-button-container {
    border-top: 1px solid #e5e5e5;
    padding-top: $padding;
  }

  .sa-error-container {
    display: none;
    background: none;
    height: auto;
    padding: 0 $padding;
    margin: 0 -20px;
    text-align: left;
    &.show {
      padding: 0 $padding;
      display: block;
      ~ fieldset input {
        background: red;
        border-bottom: 1px solid $error-color;
        box-shadow: inset 0 -1px 0 $error-color;
      }
    }

    .icon {
      display: none;
    }
    p {
      color: $error-color;
      margin-top: 0;
    }
  }
}


// Animations

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@include keyframes(animateErrorIcon) {
  0%    { transform: rotateX(100deg), scale(0.5); -webkit-transform: rotateX(100deg), scale(0.5); opacity: 0; }
  100%  { transform: rotateX(0deg),   scale(0.5); -webkit-transform: rotateX(0deg),   scale(0.5); opacity: 1; }
}