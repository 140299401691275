// Document styling



.document {
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  font-size: 12px;

  h4 {
    font-size: 16px;
  }

  table {
    font-size: 12px;
    margin-top: 10px;
    border: 1px solid #cccccc;
    min-width: 40%;
  }

  th {
    text-align: left;
    padding: 4px 5px 4px 6px;
    border: 1px solid #cccccc;
  }

  td {
    vertical-align: top;
    padding: 3px 5px 3px 6px;
    border: 1px solid #cccccc;
  }

  .footer {
    width: 100%;
    position:fixed;
    bottom: 30px;
  }

  .table-notes {
    padding-top: 5px;
    color: #444444;
    font-size: 11px;
    font-style: italic;
  }

  .table-notes ul {
    padding-left: 10px;
  }

  .items > thead > tr > th {
    font-size: 11px;
    color: #555555;
    font-weight: normal;
  }

  .items > tbody {
    border-top: 2px solid #bbbbbb;
  }

  .items > tfoot > tr {
    border-top: 2px solid #bbbbbb;
    padding-top: 10px;
  }

  ul {
    list-style-type: none;
    display: inline-block;
    padding-left: 0;
    margin-top: 0;
  }

  li {
    display: list-item;
  }

  .invoice-title {
    font-size: 80%;
    color: #555555;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

}