// Screen size hack for Galaxy Tab
$screen-md:                  960px !default;

// Colors
$brand-success:         #20BF55;
$brand-info:            #2DC7FF;
$brand-warning:         #FFE900;
$brand-danger:          #FB3640;


$gray-base: #000;
$gray-darker: lighten($gray-base, 13.5%);  // #222
$gray-dark: lighten($gray-base, 20%);  // #333
$gray: lighten($gray-base, 33.5%);  // #555
$gray-light: lighten($gray-base, 46.7%);  // #777
$gray-lighter: lighten($gray-base, 93.5%);  // #eee

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
//$font-family-sans-serif:  Arial,"Helvetica Neue", Helvetica, Arial, sans-serif !default;
//$font-family-sans-serif:  "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;


$font-size-base:          14px !default;
$font-size-small:         12px !default; // ~12px

$font-size-h3:            18px !default;

// Border radius
$border-radius-base:        3px;
$border-radius-large:       4px;
$border-radius-small:       2px;


// Navbar
$navbar-inverse-color: $gray-lighter;
$navbar-inverse-link-color: #fff;
$navbar-inverse-link-active-bg:  $brand-secondary !default;
$navbar-inverse-link-disabled-color:  $gray-light !default;

  // Nav
$nav-link-padding: 5px 15px;
$nav-pills-border-radius: 3px;
$nav-pills-active-link-hover-bg: $brand-secondary;

// Buttons
$btn-netvisor-color:         #007aca !default;
$btn-procountor-color:       #46C3B9 !default;
$btn-netbaron-color:         #fe5000 !default;
$btn-pdf-color:              #d81d00 !default;
$btn-map-color:              #ee3f35 !default;

$btn-warning-color:             #333333;
//$btn-success-color:              #333333;

// Progress bar
$progress-bar-bg: $gray-lighter;
$progress-bar-default-bg:         $gray-lighter;
$progress-bar-disabled-bg:        $gray-light;

// Labels
$label-color:                 #333 !default;
$label-default-bg:            lighten($gray-base, 70%);

$list-group-active-color: #fff;
$list-group-active-bg: $brand-secondary;
$list-group-active-border: $brand-secondary;
$list-group-active-text-color: $gray-lighter;

$pagination-active-color:              #fff !default;
$pagination-active-bg:                 $brand-secondary !default;
$pagination-active-border:             $brand-secondary !default;